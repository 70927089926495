








































import { defineComponent, reactive, ref, watch } from "@vue/composition-api"
import DefaultDialog from "@/components/dialogs/DefaultDialog.vue"
import MinutesEdit from "./MinutesEdit.vue"
import I18nFormattedMessage from "@/components/i18n/I18nFormattedMessage.vue"
import IconButton from "@/components/buttons/IconButton.vue"
import TextButton from "@/components/buttons/TextButton.vue"
import { meetingContainer } from "@/containers/MeetingsContainer"
import { memoContainer } from "@/containers/MemoContainer"
import { teamsContextContainer } from "@/containers/TeamsContextContainer"
import { minutesContainer } from "@/containers/MinutesContainer"
import { Meeting } from "@/models/Meeting"
import { workflowContainer } from "@/containers/WorkflowContainer"
import { workflowStatus } from "@/models/Workflow"
import { projectsContainer } from "@/containers/ProjectsContainer"
import Minutes from "@/models/Minutes"

interface State {
  isOpenDialog: boolean
  isFormValid: boolean
  isLoadingUpdate: boolean
}

export default defineComponent({
  props: {
    meeting: {
      type: Meeting,
      required: true,
    },
    parentId: {
      type: String,
      required: true,
    },
  },
  components: {
    I18nFormattedMessage,
    IconButton,
    TextButton,
    DefaultDialog,
    MinutesEdit,
  },
  setup(props) {
    const { state: meetingState } = meetingContainer.useContainer()
    const { styleMemo } = memoContainer.useContainer()
    const {
      exportType,
      uploadMinutesPdfAsync,
      getMinutesAsync,
    } = minutesContainer.useContainer()
    const { getContext } = teamsContextContainer.useContainer()
    const { getProjectAsync } = projectsContainer.useContainer()
    const { getWorkflowsAsync } = workflowContainer.useContainer()
    const minutesData = ref<Minutes>(
      new Minutes().setFromMeeting(props.meeting)
    )
    const state = reactive<State>({
      isOpenDialog: false,
      isFormValid: true,
      isLoadingUpdate: false,
    })
    watch(
      () => state.isOpenDialog,
      newValue => {
        if (newValue) {
          if (props.meeting) {
            minutesData.value.setFromMeeting(props.meeting)
            state.isLoadingUpdate = false
          }
        }
      }
    )
    const openPdf = async () => {
      const workflowItems = await getWorkflowsAsync(false, props.parentId)
      const approvedItemIndex = workflowItems.findIndex(
        w =>
          w.meetingId === props.meeting.id &&
          w.status === workflowStatus.Complete
      )
      // 承認済みの場合は承認済みPDFのリンクを開く
      if (approvedItemIndex !== -1) {
        window.open(workflowItems[approvedItemIndex].filePath)
      } else {
        state.isOpenDialog = true
      }
    }
    const MinutesDownload = async () => {
      const context = await getContext()
      if (props.meeting && minutesData.value) {
        state.isLoadingUpdate = true
        const isPDF = exportType.Pdf === minutesData.value.type
        try {
          const project = await getProjectAsync(context.entityId)
          const blob = await getMinutesAsync(
            minutesData.value,
            styleMemo(meetingState.memo.value ?? "")
          )
          const upFile = await uploadMinutesPdfAsync(
            project.siteId,
            props.meeting,
            blob,
            isPDF ? "pdf" : "docx"
          )
          if (upFile && upFile.webUrl) {
            window.open(upFile.webUrl)
          }
        } finally {
          state.isLoadingUpdate = false
        }
      }
    }
    return {
      openPdf,
      MinutesDownload,
      minutesData,
      state,
    }
  },
})
